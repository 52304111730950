.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #61cef7 !important;
  border-color: #61cef7 !important;
}

.publishers-dropdown-input:focus {

  box-shadow: 0 0 0 0px !important;

}

.publishers-dropdown input:focus {
  border: 1px solid #26282A !important;
}

.publishers-dropdown-input {
  /* padding-top: 8px !important; */
  padding-right: 24px !important;
  background-color: #1D1D1D !important;
  width: 280px !important;
  height: 40px !important;
  border: 1px solid #26282A !important;
}

.publishers-dropdown-input-card-selector {
  padding-right: 24px !important;
  background-color: transparent !important;
  width: 220px !important;
  height: 40px !important;
  font-size: 14px !important;
  color: white !important;
  border: 1px solid #26282A !important;
}

.publishers-dropdown-input-card {
  /* padding-top: 8px !important; */
  padding-right: 24px !important;
  background-color: #1D1D1D !important;
  width: 200px !important;
  height: 40px !important;
  border: 1px solid #26282A !important;
}

.publishers-dropdown input {
  padding-top: 8px !important
}

.App-link {
  color: #61dafb;
}

.ant-slider-handle {
  border: solid 2px #61cef7 !important;
}

.ant-slider-track {
  background-color: #61cef7 !important;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.app-list-details-header {
  font-size: 20px !important;
}

.upload-template-button {
  color: #fff !important;
  height: 29px !important;
  width: 194px !important;
  background: transparent !important;
  border: 1px solid white !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  cursor: pointer;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apiandservices-table {
  background-color: "#171717";
  border-color: "#26282a";
  background: "#171717";
  padding-left: 91px;
  padding-right: 91px;
}

.form-input {
  width: 320px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.review-sign-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px !important;
  position: absolute;
  color: #8b8b8b;
  top: 10px;
  left: 10px;
}

.checkbox-lockr-keys {
  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: gray !important;
    }
  }
  
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #dfdfdf !important;
}

.review-sign-input {
  font-size: 40px !important;
  background-color: black;
  color: white !important;
  border: 0px solid transparent;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: "Bad Script";
}

.mobile-input-class {
  width: 100% !important;
}

.review-sign-input:focus {
  font-size: 40px !important;
  background-color: black;
  color: white !important;
  border: 0px solid transparent;
}

.view-pdf-button {
  border: 1px solid #353738;
  color: white;
  height: 34px;
  width: 121px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  font-weight: 600;
  padding: 8;
}

.invitation-expired-text {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #8b8b8b !important;
}

.ant-switch-handle::before {
  background-color: #2e70d2 !important;
}

.ant-switch {
  background-color: #26282a !important;
}

.ant-switch-checked {
  background-color: #61cef7 !important;
}

.dashboard-tile-subtitle {
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #8b8b8b !important;
}

.dashboard-tile-background-logo {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 275px;
}

.more-connection-title {
  font-size: 24px;
  font-family: "Montserrat" !important;
  color: white;
  font-weight: 700;
}

.more-connection-button-text {
  font-family: "Montserrat" !important;
  color: "white";
  font-weight: 700;
  font-size: 14px;
}

.more-connection-subtitle {
  margin-top: 14px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat" !important;
  color: #8b8b8b !important;
}

.infosum-edit-button[disabled] {
  color: #26282a !important;
  border: 1px solid #26282a !important;
}

.infosum-view-credentials-text {
  margin-left: 16px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat" !important;
  cursor: pointer;
  color: #2e70d2 !important;
}

.infosum-delete-bridge-btn-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
  cursor: pointer;
  color: #f46666 !important;
}

.infosum-delete-bridge-btn {
  background-color: black !important;
  border: 1px solid #f46666 !important;
  width: 150px;
  height: 24px;
}

.dataendpoint-submit-button {
  height: 40px !important;
}

.dataendpoint-submit-icon {
    height: 32px !important;
}

.button-group {
  /* border: 1px solid #888888;  */
  display: flex; 
  border-radius: 5px; 
  gap: 5px; 
  padding: 5px;
  display: flex;
}

.lockr-delete-message-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

.data-endpoint-refresh-button {
  background-color: transparent !important;
  height: 50px !important;
  border: 1px solid white !important;
  color: white !important;
}

.data-endpoint-refresh-button:hover {
  background-color: transparent !important;
  height: 50px !important;
  border: 1px solid white !important;
  color: white !important;
}

.vendor-input-app {
  width: 400px !important;
  height: 40px !important;
  padding-top: 0px !important;
  padding-left: 64px !important;
  padding-bottom: 0px !important;
  color: white !important;
  border: 1px solid #26282a !important;
  font-family: "Montserrat";
  background-color: #171717 !important;
}

.vendor-add-url-button {
  display: "block";
  min-width: 103px;
  height: 40px !important;
  margin-left: 16px;
}

.lockr-delete-confirm-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  color: #ffffff;
}

.lockr-data-endpoint-input {
  background: #26282a;
  min-height: 50px;
  min-width: 600px;
  max-width: 600px;
  display: flex;
  align-items: center;
  margin-top: 4px;
  padding-left: 8px;
  color: white;
  position: relative;
  border: 0px transparent;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  outline: none;
}

.lockr-full-refresh-title {
  font-weight: 700;
  font-family: "Montserrat";
  font-size: 36px !important;
  color: white;
}

.lockr-full-refresh-description {
  font-weight: 400;
  font-family: "Montserrat";
  font-size: 16px !important;
  color: white;
}

.lockr-data-endpoint-input:focus-visible {
  background: #26282a;
  min-height: 50px;
  min-width: 600px;
  max-width: 600px;
  outline: none;
  display: flex;
  align-items: center;
  margin-top: 4px;
  padding-left: 8px;
  color: white;
  position: relative;
  border: 0px transparent;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
}

.lockr-api-docs-modal-title {
  font-family: "Montserrat";
  font-size: 44px !important;
  margin-top: 0;
  font-weight: 700;
  color: white;
}

.lockr-api-docs-modal-subtitle {
  font-family: "Montserrat";
  font-size: 16px !important;
  margin-top: 0;
  font-weight: 400;
  color: white !important;
}

.lockr-api-docs-link {
  font-family: "Montserrat";
  font-size: 16px !important;
  margin-top: 0;
  font-weight: 400;
  cursor: pointer;
  color: #50e8b1 !important;
}

.lockr-api-docs-button-text {
  font-family: "Montserrat";
  color: #26282a;
  font-weight: 700;
  font-size: 16px;
}

@keyframes animate-opacity-full {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animate-opacity-out {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.dashboard-tile-animate-opacity {
  animation: animate-opacity-full 2s;
}

.dashboard-tile-animate-opacity-out {
  animation: animate-opacity-out 2s;
}

.dashboard-tile-dots {
  display: none !important;
}

.sensitivity-setting-domain-title {
  margin-top: 20px;
  color: white;
  font-weight: 500;
  font-family: "Montserrat";
  font-size: 14px;
}

.ant-slider-track {
  background-color: white !important; 
}
.ant-slider-rail {
  background-color: rgba(53, 55, 56, 1) !important ;
}
.custom-steps-dark .ant-steps-item {
  padding: 8px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.custom-steps-dark .ant-steps-item-process .ant-steps-item-container {
  color: #61cef7; /* Light blue text */
}

.custom-steps-dark .ant-steps-item-tail::after {
  background-color: #000000; /* Light blue step line */
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #61dafb !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #61dafb !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: #000000 !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #000000 !important ;
}

.custom-steps-dark .ant-steps-item-wait .ant-steps-item-container {
  color: #8a8a8a; /* Grey text for steps that haven't been started */
}

/* Inherit text color from container */
.custom-steps-dark .ant-steps-item-title,
.custom-steps-dark .ant-steps-item-description {
  color: inherit;
}

.custom-steps-dark .ant-steps-item-finish .ant-steps-item-icon {
  background: #50e8b1 !important; 
  border: none;

  span {
    color: inherit; /* Inherit the text color for the checked step */
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.custom-checkbox-background .ant-checkbox-inner {
  
  border:1px solid #353738 !important;
  background-color: #1D1D1D;

}

.custom-steps-dark {
  display: flex;
  /* justify-content: center; */
  padding: 0;
  list-style: none;
  width: 100%;
  align-items: center;
  margin-bottom: 0px !important;
  gap: 25px;
}

@media only screen and (min-width: 1299px)  {
  .custom-steps-dark{
    width: 100%;

  }
}

.custom-step {
  width: 40px; 
  height: 5px;
  background-color: #ccc;
  cursor: pointer;
  border-radius: 25px;
}

.custom-step.active {
  background-color: #61cef7;
}

.fontSize18 {
  font-size: 18px !important;
}
.illumna-input{
  width: 365px !important;
}