.float-label {
  position: relative;
      color: #828282;
/* padding: 15.85px 20px 17.65px 20px */
  /* margin-bottom: 12px; */
}

.label {
  padding: 15.85px 20px 17.65px 20px;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0px;
  top: 0px;
  transition: 0.2s ease all;
}

.label-float {
    padding: 0 16px;
    top: 6px;
    font-family: 'Montserrat',sans-serif;
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 10px!important;
    line-height: 18px!important;
    color: #828282;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
